main {
    background: unset;
    border-radius: unset;
    box-shadow: unset;
    padding: 1.5rem 3rem;
}

#login {
    background: white;
    border-radius: .6rem;
    box-shadow: .3rem .3rem 1rem #4d4d4d;
    margin: 5% auto;
    padding: 1.5rem;
    width: 45rem;

    h4 {
        background: #6ca0e8;
        background-image: linear-gradient(#6ca0e8, #5680ba);
        border-top-left-radius: .5rem;
        border-top-right-radius: .5rem;
        color: #fdfdfd;
        margin: -1.5rem -1.5rem .5rem -1.5rem;
        padding: .5rem 1.5rem;
        text-shadow: .2rem .2rem .4rem #282828;
    }

    form {
        margin-top: 2rem;

        .row {
            margin: 1rem auto;
            text-align: center;

            label,.label-placeholder {
                display: inline-block;
                font-weight: normal;
                width: 8rem;
            }

            input,.input-container {
                display: inline-block;
                line-height: 1.5;
                width: 15rem;
            }

            input {
                padding: .1rem .2rem;
            }

            .input-container {
                padding-right: .8rem;
                text-align: left;

                input {
                    padding: .2rem .6rem;
                    width: auto;
                }
            }
        }
    }

    .request-credentials {
        background: #e4e4e4;
        background-image: linear-gradient(#e4e4e4, #D2D2D2);
        border-bottom-left-radius: .5rem;
        border-bottom-right-radius: .5rem;
        color: #3E3E3E;
        line-height: 1.2;
        padding: 1rem;
        margin: 1.5rem -1.5rem -1.5rem -1.5rem;
        text-align: center;

        a {
            color: #5680ba;
            display: block;
            font-size: 1rem;
            font-style: italic;
            text-decoration: underline;
            margin: 0 auto;
        }
    }
}

#login-forgot {
    background: #FFF;
    border-radius: .6rem;
    box-shadow: .3rem .3rem 1rem #4d4d4d;
    margin: 2rem auto 14rem;
    text-align: center;
    width: 60rem;

    .failure {
        background: #FDD;
        color: #A00;
        margin-top: 1rem;
    }

    .success {
        background: #DFD;
        color: #080;
        margin-top: 1rem;
    }

    p {
        text-align: left;
    }

    form {
        display: inline-block;
        margin: 2rem auto;
        text-align: center;
        width: 40rem;
    }

    input {
        padding: .3rem;
        line-height: 1.5;
        width: 100%;
    }

    .good {
        background: #080;
        color: #FFF;
        font-weight: bolder;
        margin-top: 2rem;

        &:hover {
            background: #070;
        }

        &.big {
            border-radius: .6rem;
            box-sizing: content-box;
            font-size: 1.6rem;
            font-weight: bolder;
            padding: .4rem .3rem;
        }
    }
}

#login-request {
    background: #FFF;
    border-radius: .6rem;
    box-shadow: .3rem .3rem 1rem #4d4d4d;
    margin: 2rem auto 14rem;

    .container {
        display: block;
        margin: 2rem auto;
        width: 51rem;

        .back {
            float: right;
        }

        label {
            display: block;
            font-weight: bolder;
            margin-bottom: 1rem;
            margin-top: 1.5rem;
        }

        input, textarea {
            line-height: 1.5;
            width: 50rem;
        }

        input[type=submit] {
            display: inline-block;
            float: right;
            margin-top: 1.5rem;
            padding: .5rem 1rem;
            width: unset;
        }
    }
}

#request-success {
    background: #FFF;
    border-radius: .6rem;
    box-shadow: .3rem .3rem 1rem #4d4d4d;
    margin: 2rem auto 14rem;

    .container {
        display: block;
        margin: 2rem auto;
        width: 60rem;

        .back {
            float: right;
        }

        h3 {
            margin-top: 1rem;
        }
    }
}

#set-account-main {
    background: #FFF;
    border-radius: 1rem;
    width: 51rem;

    .container {
        display: block;
        padding: 1rem 0;
    }

    input {
        padding: .3rem;
        width: 98%;
    }

    .good {
        background: #080;
        color: #FFF;
        font-weight: bolder;
        margin-top: 2rem;

        &:hover {
            background: #070;
        }

        &:disabled {
            background: #888;
        }

        &.big {
            border-radius: .6rem;
            box-sizing: content-box;
            font-size: 1.6rem;
            font-weight: bolder;
            padding: .4rem .3rem;
        }
    }

    .strength {
        .status {
            background: #FFC;
            border: thin solid #CCC;
            box-sizing: border-box;
            margin-top: 2.5rem;
            padding: 1rem;
            width: 100%;

            .label {
                font-weight: 900;
            }

            .score-bar {
                display: inline-block;
                margin-left: 2.5rem;
                height: 1rem;

                &.score-0 {
                    background: red;
                    width: 2.5rem;
                }

                &.score-1 {
                    background: orange;
                    width: 5rem;
                }

                &.score-2 {
                    background: yellow;
                    width: 7.5rem;
                }

                &.score-3 {
                    background: lightgreen;
                    width: 10rem;
                }

                &.score-4 {
                    background: green;
                    width: 12.5rem;
                }
            }

            meter {
                display: inline-block;
                margin-left: 1rem;
                height: 1rem;
                width: 15rem;
            }

            .score-text {
                &.score-0 {
                    margin-left: 17rem;
                }

                &.score-1 {
                    margin-left: 19.5rem;
                }

                &.score-2 {
                    margin-left: 20.5rem;
                }

                &.score-3 {
                    margin-left: 23rem;
                }

                &.score-4 {
                    margin-left: 26.5rem;
                }
            }
        }
    }
}